<template>
  <div class="coefficientPar">
    <a-spin :spinning="spinning">
      <div>
        <div
          class="actions flex jc-end mb-4"
          :style="
            isDesktop()
              ? 'min-height:32px;position: absolute;top:0;right:2vw;padding-top:5px;z-index:1'
              : 'min-height:32px;position: absolute;top:-10px;right:2vw;padding-top:5px;z-index:1'
          "
        >
          <a-button v-if="isEditProduction" type="default" class="mr-3" @click="cancal">取消</a-button>
<!-- v-if="systemTypes.every(item => item != 'IMAGE_TRANSLATION_SYSTEM')||(taskDetail.stageName!='翻译'&&taskDetail.stageName!='校对'&&taskDetail.stageName!='终审')" -->
          <template>
            <a-button
              :icon="!isEditProduction ? 'edit' : 'save'"
              type="primary"
              v-has="'task:coefficientPar'"
              @click="editProduction"
              >{{ !isEditProduction ? '编辑' : '保存' }}</a-button
            >
          </template>
        </div>
        <a-form-model
          v-if="isEditProduction"
          :style="
            isDesktop()
              ? 'padding-top:40px;overflow-y: overlay;height: 62.2vh;'
              : 'padding-top:40px;padding-bottom:40px;overflow-y: overlay;'
          "
          ref="form"
        >
          <a-form-model-item v-for="item in formData" class="edit-form" :key="item.id" v-bind="layout">
            <template v-if="item.cardList[0]">
              <div
                :class="item.cardList[0].dataList[0].valueRequired == 'YES' ? 'required' : 'no-required'"
                slot="label"
              >
                {{ item.title }}
                <span style="margin-left:-2px">:</span>
                <div v-if="item.cardList[0].dataList && item.cardList.length > 1">
                  <div :ref="item.cardList[0].id + 'TEXTAREA'" class="description-div">
                    {{ getSelectCount(item.cardList[0]) + `${item.description ? item.description : ''}`
                    }}<!-- {{ !item.canSync ? '（已更新数据）' : '' }} -->
                  </div>
                </div>
                <div v-if="item.cardList[0].dataList && item.cardList.length == 1">
                  <div
                    v-if="item.cardList[0].type != 'TEXTAREA'"
                    :class="
                      item.cardList[0].dataList[0].formValues.length > 1 && item.cardList[0].type != 'TAG'
                        ? 'description-div'
                        : 'description-div-one'
                    "
                  >
                    {{ getSelectCount(item.cardList[0]) + `${item.description ? item.description : ''}`
                    }}<!-- {{ !item.canSync ? '（已更新数据）' : '' }} -->
                  </div>
                  <div v-else :ref="item.cardList[0].id + 'TEXTAREA'" :class="getClassName(item.cardList[0], true)">
                    {{ getSelectCount(item.cardList[0]) + `${item.description ? item.description : ''}`
                    }}<!-- {{ !item.canSync ? '（已更新数据）' : '' }} -->
                  </div>
                </div>
              </div>
            </template>

            <template v-for="wrapper in item.cardList">
              <div
                v-if="wrapper.dataList"
                :key="wrapper.id"
                :style="
                  wrapper.type == 'TEXTAREA'
                    ? 'margin-bottom:2px;display:flex;flex-wrap:wrap'
                    : 'display:flex;flex-wrap:wrap'
                "
              >
                <a-input
                  style="margin-bottom: 6px;margin-top:4px;"
                  v-if="wrapper.type == 'INPUT'"
                  :ref="'input' + wrapper.id"
                  :placeholder="wrapper.dataList[0].description"
                  v-model="wrapper.dataList[0].formValues[0].value"
                  @blur="
                    e => {
                      inputBlur(e, wrapper)
                    }
                  "
                  :disabled="isSucDis(item)"
                  @change="
                    e => {
                      inputChange(e, wrapper)
                    }
                  "
                />
                <a-input-number
                  style="margin-bottom: 6px;margin-top:4px;"
                  :min="0"
                  v-if="wrapper.type == 'INPUT_NUMBER'"
                  :ref="'input' + wrapper.id"
                  :placeholder="wrapper.dataList[0].description"
                  v-model="wrapper.dataList[0].formValues[0].value"
                  @blur="
                    e => {
                      inputNumberBlur(e, wrapper, item)
                    }
                  "
                  :disabled="isSucDis(item)"
                  @change="
                    e => {
                      inputNumberChange(e, wrapper, item)
                    }
                  "
                />
                <div
                  v-if="wrapper.type == 'DYNAMIC_FORM'"
                  style="display:flex;justify-content: space-between;align-items: center;width: 580px;margin-top:-6px;"
                >
                  <template v-for="data in wrapper.dataList">
                    <a-form-model-item :key="data.id" :label="data.value" class="form-select" v-bind="inlineLayout">
                      <div v-if="data.type != 'INPUT'" style="padding-top:6px;">
                        <div v-for="(formValue, index) in data.formValues" :key="index">
                          <a-select
                            :ref="'select' + data.id + index"
                            allowClear
                            :placeholder="wrapper.dataList[0].description"
                            :style="
                              wrapper.dataList.length > 1 && !data.value
                                ? 'width:243px'
                                : wrapper.dataList.length > 1 && data.value
                                ? 'width:173px'
                                : data.value
                                ? 'width:430px;'
                                : 'width:18vw'
                            "
                            v-model="formValue.value"
                            @change="
                              e => {
                                formSelectChange(e, data, wrapper.dataList, index, item)
                              }
                            "
                            :disabled="isSucDis(item)"
                          >
                            <a-select-option v-for="option in data.options" :key="option.id" :value="option.value">{{
                              option.label
                            }}</a-select-option>
                          </a-select>
                          <div
                            :ref="data.id + '' + index"
                            class="ant-form-explain"
                            style="width: 100%;color:#f5222d;display:none"
                          >
                            请选择{{ item.title }}
                          </div>
                        </div>
                      </div>
                      <div v-else style="margin-top:6px;">
                        <div v-for="(formValue, index) in data.formValues" :key="index">
                          <a-input
                            :disabled="isSucDis(item)"
                            :ref="'input' + data.id + index"
                            :placeholder="wrapper.dataList[0].type == 'INPUT' ? wrapper.dataList[0].description : ''"
                            :style="
                              wrapper.dataList.length > 1 && !data.value
                                ? 'width:243px'
                                : wrapper.dataList.length > 1 && data.value
                                ? 'width:173px'
                                : data.value
                                ? 'width:430px;'
                                : 'width:18vw'
                            "
                            @blur="
                              e => {
                                formInputBlur(e, data, wrapper.dataList, index)
                              }
                            "
                            @change="
                              e => {
                                formInputChange(e, data, wrapper.dataList, index)
                              }
                            "
                            v-model="formValue.value"
                          />
                          <div
                            :ref="data.id + '' + index"
                            class="ant-form-explain"
                            style="width: 100%;color:#f5222d;display:none"
                          >
                            请输入{{ item.title }}
                          </div>
                        </div>
                      </div>
                    </a-form-model-item>
                  </template>
                  <div
                    v-if="wrapper.canAdd"
                    :class="wrapper.dataList.length > 1 ? '' : 'only'"
                    style="min-width:45px;margin-left:10px;display: flex;justify-content: space-between;height: 100%;padding-top:16px;"
                  >
                    <a-button
                      :disabled="isSucDis(item)"
                      class="item-btn"
                      shape="circle"
                      size="small"
                      icon="minus"
                      @click="delItem(wrapper)"
                    />
                    <a-button
                      :disabled="isSucDis(item)"
                      style="margin-left:8px;"
                      class="item-btn"
                      shape="circle"
                      size="small"
                      icon="plus"
                      @click="addItem(wrapper)"
                    />
                  </div>
                  <div v-if="!wrapper.canAdd" style="width:55px;margin-left:10px;"></div>
                </div>
                <div class="form-select" style="width:18vw" v-if="wrapper.type == 'SELECT'">
                  <a-select
                    :disabled="isSucDis(item)"
                    :ref="'select' + wrapper.id"
                    allowClear
                    :showArrow="true"
                    :mode="wrapper.dataList[0].selectCount == 1 ? '' : 'multiple'"
                    v-model="wrapper.dataList[0].selectValues"
                    :placeholder="wrapper.dataList[0].description"
                    @change="
                      e => {
                        selectChange(e, wrapper)
                      }
                    "
                  >
                    <a-select-option
                      v-for="option in wrapper.dataList[0].options"
                      :key="option.id"
                      :value="option.value"
                    >
                      <div slot="option">
                        {{ option.label }}
                      </div>
                    </a-select-option>
                  </a-select>
                </div>
                <div class="form-select" style="width:18vw" v-if="wrapper.type == 'TAG' && !isSucDis(item)" @click.stop>
                  <div style="height:40px;display: flex;align-items: center;">
                    <div @click.self="isTagShow(wrapper)" :class="wrapper.isTagShow ? 'tag-select-show' : 'tag-select'">
                      <template v-if="wrapper.dataList[0].formValues && wrapper.dataList[0].formValues[0].value">
                        <a-tag
                          closable
                          @close="
                            tagClose(formValue.value, wrapper.dataList[0].formValues, wrapper.dataList[0].options)
                          "
                          :key="formValue.value"
                          v-for="formValue in wrapper.dataList[0].formValues"
                          >{{ formValue.value }}</a-tag
                        >
                      </template>
                      <div class="tag-select-open " :style="wrapper.isTagShow ? '' : 'height:0px;border:none'">
                        <template v-if="wrapper.dataList[0].formValues[0].value">
                          <a-checkable-tag
                            :ref="option.label"
                            v-for="option in wrapper.dataList[0].options"
                            :key="option.label"
                            v-model="option.checked"
                            :style="
                              wrapper.dataList[0].formValues.length >= wrapper.dataList[0].selectCount &&
                              !option.checked
                                ? 'pointer-events: none;color: rgba(0, 0, 0, 0.65);background: #fafafa;border: 1px solid #d9d9d9;'
                                : ''
                            "
                            @change="tagChange(option.checked, wrapper, option)"
                            >{{ option.label }}</a-checkable-tag
                          >
                        </template>
                        <template v-else>
                          <a-checkable-tag
                            :ref="option.label"
                            v-for="option in wrapper.dataList[0].options"
                            :key="option.label"
                            v-model="option.checked"
                            @change="tagChange(option.checked, wrapper, option)"
                            >{{ option.label }}</a-checkable-tag
                          >
                        </template>
                      </div>
                      <span class="ant-select-arrow">
                        <a-icon style="color:rgba(0, 0, 0, 0.25);" :type="wrapper.isTagShow ? 'up' : 'down'"></a-icon>
                      </span>
                    </div>
                  </div>
                </div>
                <div v-else-if="wrapper.type == 'TAG' && isSucDis(item)" class="dis-tag">
                  <span class="ant-select-arrow">
                    <a-icon style="color:rgba(0, 0, 0, 0.25);" :type="'down'"></a-icon>
                  </span>
                </div>
                <a-textarea
                  :disabled="isSucDis(item)"
                  v-if="wrapper.type == 'TEXTAREA'"
                  :ref="'input' + wrapper.id"
                  :auto-size="{ minRows: 1, maxRows: 5 }"
                  style="width:18vw;margin-top:4px;"
                  :placeholder="wrapper.dataList[0].description"
                  v-model.lazy="wrapper.dataList[0].formValues[0].value"
                  @blur="
                    e => {
                      inputBlur(e, wrapper)
                    }
                  "
                  @change="
                    e => {
                      inputChange(e, wrapper, item)
                    }
                  "
                />
                <div class="zsxz" v-if="wrapper.type == 'INPUT' || wrapper.type == 'TEXTAREA'">
                  {{ getRange(wrapper.dataList[0]) }}
                </div>
                <div
                  v-if="wrapper.type == 'INPUT' || wrapper.type == 'TEXTAREA' || wrapper.type == 'INPUT_NUMBER'"
                  :ref="wrapper.id"
                  class="ant-form-explain"
                  style="color:#f5222d;display:none;margin-left:5px;align-items: center;"
                >
                  请输入整数数字
                </div>
                <div
                  v-if="wrapper.type == 'SELECT'"
                  :ref="wrapper.id"
                  class="ant-form-explain"
                  style="width: 100%;color:#f5222d;display:none"
                >
                  请选择{{ item.title }}
                </div>
                <!--                   <div
                    v-if="!wrapper.canAdd"
                    style="min-width:115px;position: relative;"
                  ><span
                      v-if="wrapper.dataList[0].selectCount>1"
                      class="select-span"
                    >{{'选项个数：'+wrapper.dataList[0].selectCount}}</span></div> -->
              </div>
            </template>
          </a-form-model-item>
        </a-form-model>
        <a-form-model
          v-else
          class="show-form"
          :style="isDesktop() ? 'padding-top:40px' : 'height:auto;padding-top:40px;'"
        >
          <a-form-model-item v-for="item in formData" :key="item.id" v-bind="layout">
            <template v-if="item.cardList[0]">
              <div
                :class="item.cardList[0].dataList[0].valueRequired == 'YES' ? 'required' : 'no-required'"
                slot="label"
              >
                {{ item.title }}
                <span style="margin-left:-2px">:</span>
                <!--                 <template v-for="wrapper in item.cardList">
                  <div
                    v-if="wrapper.type != 'TEXTAREA'"
                    :key="wrapper.id"
                    :class="wrapper.dataList[0].formValues.length > 1 ? 'description-div' : 'description-div-one'"
                  >
                    {{
                      `${wrapper.dataList[0].valueRequired == 'YES' ? '必填' : '非必填'}` +
                        getSelectCount(wrapper) +
                        `${item.description ?"/"+ item.description : ''}`
                    }}
                  </div>
                  <div
                    v-else
                    :ref="wrapper.id + 'TEXTAREA'"
                    :key="wrapper.id"
                    :class="
                      wrapper.dataList[0].formValues[0].value.indexOf('\n') == -1
                        ? 'description-div-one'
                        : 'description-div'
                    "
                  >
                    {{
                      `${wrapper.dataList[0].valueRequired == 'YES' ? '必填' : '非必填'}` +
                        getSelectCount(wrapper) +
                        `${item.description ? "/"+item.description : ''}`
                    }}
                  </div>
                </template> -->
                <div v-if="item.cardList[0].dataList && item.cardList.length > 1">
                  <div :ref="item.cardList[0].id + 'TEXTAREA'" class="description-div">
                    {{ getSelectCount(item.cardList[0]) + `${item.description ? item.description : ''}`
                    }}<!-- {{ !item.canSync ? '（已更新数据）' : '' }} -->
                  </div>
                </div>
                <div v-if="item.cardList[0].dataList && item.cardList.length == 1">
                  <div
                    v-if="item.cardList[0].type != 'TEXTAREA'"
                    :class="
                      item.cardList[0].dataList[0].formValues.length > 1 && item.cardList[0].type != 'TAG'
                        ? 'description-div'
                        : 'description-div-one'
                    "
                  >
                    {{ getSelectCount(item.cardList[0]) + `${item.description ? item.description : ''}`
                    }}<!-- {{ !item.canSync ? '（已更新数据）' : '' }} -->
                  </div>
                  <div v-else :ref="item.cardList[0].id + 'TEXTAREA'" :class="getClassName(item.cardList[0], false)">
                    {{ getSelectCount(item.cardList[0]) + `${item.description ? item.description : ''}`
                    }}<!-- {{ !item.canSync ? '（已更新数据）' : '' }} -->
                  </div>
                </div>
              </div>
            </template>

            <template v-for="wrapper in item.cardList">
              <div :key="wrapper.id" style="display:flex">
                <div v-if="wrapper.type == 'INPUT' || wrapper.type == 'INPUT_NUMBER'">
                  {{ wrapper.dataList[0].formValues[0].value }}
                </div>
                <div
                  v-if="wrapper.type == 'DYNAMIC_FORM'"
                  style="display:flex;justify-content: space-between;align-items: center;width: 100%;margin-bottom:-12px;"
                >
                  <template v-for="(data, idx) in wrapper.dataList">
                    <a-form-model-item
                      :key="idx"
                      :label="data.value"
                      :style="wrapper.dataList.length > 1 ? 'min-width:40%' : 'min-width:105%'"
                      class="form-select"
                      v-bind="inlineLayout"
                    >
                      <template v-for="(formValue, index) in data.formValues">
                        <div v-if="data.type == 'INPUT'" :key="index">{{ formValue.value }}</div>
                        <div v-else :key="index">
                          <a-tag v-if="formValue.value && data.type == 'TAG'">{{ formValue.value }}</a-tag>
                          <div v-if="formValue.value && data.type == 'SELECT'">
                            <span class="ant-badge-status-dot ant-badge-status-processing"> </span>
                            <span>{{ formValue.value }}</span>
                          </div>
                        </div>
                      </template>
                    </a-form-model-item>
                  </template>
                </div>
                <div class="form-select" style="width:100%;" v-if="wrapper.type == 'SELECT' || wrapper.type == 'TAG'">
                  <template v-for="(tag, tagIndex) in wrapper.dataList[0].formValues">
                    <a-tag v-if="tag.value && wrapper.type == 'TAG'" :key="tagIndex">{{ tag.value }}</a-tag>
                    <div :key="tagIndex" v-if="tag.value && wrapper.type == 'SELECT'">
                      <span class="ant-badge-status-dot ant-badge-status-processing"> </span>
                      <span>{{ tag.value }}</span>
                    </div>
                  </template>
                </div>
                <div
                  v-if="wrapper.type == 'TEXTAREA'"
                  :ref="'input' + wrapper.id"
                  style="  white-space: pre-line;word-break: break-word;line-height: 1.5;margin-top: -12px;height:auto"
                >
                  {{ wrapper.dataList[0].formValues[0].value }}
                </div>
              </div>
            </template>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-spin>
  </div>
</template>

<script>
import Vue from 'vue'
import { ACCESS_TOKEN, USER_INFO } from '@/store/mutation-types'
import '@/styles/form.styl'
import { postAction, getAction } from '@/api/manage'
import infoItem from '@/components/my/info-item.vue'
import DynamicFormItem from '@/components/dynamic-form-item'
import { mixinDevice } from '@/utils/mixin.js'
export default {
  mixins: [mixinDevice],
  components: {
    infoItem,
    DynamicFormItem
  },
  props: {
    tab: { type: String, default: '' },
    parent: {
      type: Object,
      default: () => {}
    },
    taskDetail: {
      type: Object,
      default: () => {}
    },
    isAddProduction: {
      type: Boolean,
      default: false
    },
    productionData: {
      type: Object,
      default: () => ({})
    },
    adminUsers: {
      type: Array,
      default: () => []
    },
    systemTypes: {
      type: Array,
      default: () => []
    },
    platformList: {
      type: Array,
      default: () => []
    },
    localPercentOptions: {
      type: Array,
      default: () => [
        { label: '高', value: 0 },
        { label: '中', value: 1 },
        { label: '低', value: 2 },
        { label: '不需要', value: 3 }
      ]
    },
    params: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      spinning: false,
      validate: true,
      currentType: 1,
      isAdmin: false,
      relationId: '',
      html: '',
      flex: {
        type: 'flex',
        justify: 'space-between'
      },
      formData: {},
      oldData: {},
      isEditProduction: false,
      labelWidth: '80',
      layout: {
        labelCol: { style: this.isDesktop() ? 'width: 30% !important;' : 'width: 50% !important;', span: 6 },
        wrapperCol: { span: 14 }
      },
      inlineLayout: {
        labelCol: { style: 'width: 70px;', span: 4 },
        wrapperCol: { span: 20 }
      }
    }
  },
  computed: {
    localPercent() {
      return this.localPercentOptions.find(({ value }) => value == this.formData.localPercent)
    }
  },
  watch: {
    tab(n) {
      this.isEditProduction = false
      this.getFormData()
    }
    /*     formData: {
      handler: function(n, o) {
        let newData = JSON.stringify(this.formData)
        let oldData = JSON.stringify(this.oldData)
        console.log(newData==oldData)
        if (newData != oldData) {
          this.isChange=true
        } else {
          this.isChange=false
        }
      },
      deep: true
    } */
  },
  created() {
    this.getFormData()
    const userInfo = Vue.ls.get(USER_INFO)
    this.userInfo = Object.assign({}, userInfo)
    this.isAdmin = this.userInfo.roles.some(item => {
      return item.roleCode == 'admin' || item.roleCode == 'boss'
    })
  },
  mounted() {},
  beforeDestroy() {
    document.removeEventListener('click', this.bodyCloseMenus)
  },
  methods: {
    cancal() {
      let newData = JSON.stringify(this.formData)
      let oldData = JSON.stringify(this.oldData)
      let self = this
      if (oldData != newData) {
        this.$confirm({
          title: '提示',
          content: '您尚未保存数据，需要保存吗？',
          onOk() {
            self.editProduction()
          },
          onCancel() {
            self.isEditProduction = false
            self.getFormData()
          }
        })
      } else {
        self.isEditProduction = false
        self.getFormData()
      }
    },
    isSucDis(item) {
      let dis = true
      for (let p of this.userInfo.positions) {
        if (
          item.positions.some(i => {
            return i.positionId == p.id && i.type == 'EDIT'
          }) &&
          item.stages.some(i => {
            return this.taskDetail.stageId == i.stageId && i.type == 'EDIT'
          })
        ) {
          dis = false
          break
        }
      }
      if (this.isAdmin) dis = false
      return dis
    },
    getClassName(wrapper, isInput) {
      setTimeout(() => {
        if (wrapper && isInput) {
          if (this.$refs['input' + wrapper.id][0].$el.style.height.split('p')[0] > 35) {
            this.$refs[wrapper.id + 'TEXTAREA'][0].className = 'description-div'
            return 'description-div'
          } else {
            this.$refs[wrapper.id + 'TEXTAREA'][0].className = 'description-div-one'
            return 'description-div-one'
          }
        } else if (wrapper && !isInput) {
          if (this.$refs['input' + wrapper.id][0].offsetHeight > 42) {
            this.$refs[wrapper.id + 'TEXTAREA'][0].className = 'description-div'
            return 'description-div'
          } else {
            this.$refs[wrapper.id + 'TEXTAREA'][0].className = 'description-div-one'
            return 'description-div-one'
          }
        }
      }, 0)
    },
    getRange(data) {
      if (data.limitRange) {
        switch (data.limitRange.type) {
          case 'GT':
            return (
              data.limitRange.value &&
              data.formValues[0].value &&
              data.formValues[0].value.length + '/>' + data.limitRange.value + '字'
            )
            break
          case 'LT':
            return (
              data.limitRange.value &&
              data.formValues[0].value &&
              data.formValues[0].value.length + '/<' + data.limitRange.value + '字'
            )
            break
          case 'BETWEEN':
            return (
              data.limitRange.end &&
              data.formValues[0].value &&
              data.formValues[0].value.length + '/' + data.limitRange.start + '~' + data.limitRange.end + '字'
            )
            break
        }
      }
    },
    tagClose(formValue, formValues, options) {
      this.$nextTick(() => {
        options[
          options
            .map(item => {
              return item.value
            })
            .indexOf(formValue)
        ].checked = false
        let deleteIndex = formValues
          .map(item => {
            return item.value
          })
          .indexOf(formValue)
        if (formValues.length == 1) {
          formValues.pop()
          formValues.push({ value: '' })
        } else {
          formValues.splice(deleteIndex, 1)
        }
      })
    },
    tagChange(e, wrapper, option) {
      if (e) {
        if (wrapper.dataList[0].formValues && wrapper.dataList[0].formValues[0].value != '') {
          wrapper.dataList[0].formValues.push({ value: option.label })
        } else if (wrapper.dataList[0].formValues && wrapper.dataList[0].formValues[0].value == '') {
          wrapper.dataList[0].formValues.pop()
          wrapper.dataList[0].formValues.push({ value: option.label })
        }
      } else {
        let deleteIndex = wrapper.dataList[0].formValues
          .map(item => {
            return item.value
          })
          .indexOf(option.label)
        if (wrapper.dataList[0].formValues.length == 1) {
          wrapper.dataList[0].formValues = [{ value: '' }]
        } else {
          wrapper.dataList[0].formValues.splice(deleteIndex, 1)
        }
      }
    },
    bodyCloseMenus(e) {
      if (this.formData.length != undefined) {
        this.formData.forEach(item => {
          if (item.cardList) {
            item.cardList.forEach(wrapper => {
              wrapper.isTagShow = false
            })
          }
        })
      }
    },
    isTagShow(wrapper) {
      wrapper.isTagShow = !wrapper.isTagShow
    },
    getSelectCount(data) {
      if (data.type == 'SELECT' || data.type == 'TAG') {
        if (data.dataList[0].selectCount == 1) {
          return '单选/'
        } else if (data.dataList[0].selectCount > 1) {
          return '最多' + data.dataList[0].selectCount + '个' + '/'
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    formInputBlur(e, data, dataList, index) {
      if (!e.target.value && dataList[0].valueRequired == 'YES') {
        this.$nextTick(() => {
          e.target.style.borderColor = '#f5222d'
        })
      }
    },
    formInputChange(e, data, dataList, index) {
      if (e.target.value && dataList[0].valueRequired == 'YES') {
        this.$refs[data.id + '' + index][0].style.display = 'none'
        e.target.style.borderColor = '#d9d9d9'
      }
    },
    inputBlur(e, wrapper) {
      if (!e.target.value && wrapper.dataList[0].valueRequired == 'YES') {
        this.$nextTick(() => {
          e.target.style.borderColor = '#f5222d'
          e.target.classList.add('antd-input-vis')
        })
      } else if (e.target.value && wrapper.dataList[0].valueRequired == 'YES') {
        this.$refs[wrapper.id][0].style.display = 'none'
        e.target.style.borderColor = '#d9d9d9'
        e.target.classList.remove('antd-input-vis')
      }
      if (wrapper.dataList[0].limitRange) {
        switch (wrapper.dataList[0].limitRange.type) {
          case 'GT':
            if (e.target.value.length < wrapper.dataList[0].limitRange.value) {
              /* this.$refs[wrapper.id][0].style.display = 'block' */
              const ref = 'input' + wrapper.id
              this.$refs[ref][0].$el.style.borderColor = '#f5222d'
            } else {
              this.$refs[wrapper.id][0].style.display = 'none'
              e.target.style.borderColor = '#d9d9d9'
            }
            break
          case 'LT':
            if (e.target.value.length > wrapper.dataList[0].limitRange.value) {
              /* this.$refs[wrapper.id][0].style.display = 'block' */
              const ref = 'input' + wrapper.id
              this.$refs[ref][0].$el.style.borderColor = '#f5222d'
            } else {
              this.$refs[wrapper.id][0].style.display = 'none'
              e.target.style.borderColor = '#d9d9d9'
            }
            break
          case 'BETWEEN':
            if (
              e.target.value.length < wrapper.dataList[0].limitRange.start ||
              e.target.value.length > wrapper.dataList[0].limitRange.end
            ) {
              /* this.$refs[wrapper.id][0].style.display = 'block' */
              const ref = 'input' + wrapper.id
              this.$refs[ref][0].$el.style.borderColor = '#f5222d'
            } else {
              this.$refs[wrapper.id][0].style.display = 'none'
              e.target.style.borderColor = '#d9d9d9'
            }
            break
        }
      }
    },
    inputChange(e, wrapper, item) {
      setTimeout(() => {
        if (wrapper.type == 'TEXTAREA') {
          if (e.target.style.height.split('p')[0] > 35) {
            this.$refs[wrapper.id + 'TEXTAREA'][0].className = 'description-div'
          } else {
            this.$refs[wrapper.id + 'TEXTAREA'][0].className = 'description-div-one'
          }
        }
      }, 0)
      if (e.target.value && wrapper.dataList[0].valueRequired == 'YES') {
        this.$refs[wrapper.id][0].style.display = 'none'
        e.target.style.borderColor = '#d9d9d9'
        e.target.classList.remove('antd-input-vis')
      } else if (!e.target.value && wrapper.dataList[0].valueRequired == 'YES') {
        /* this.$refs[wrapper.id][0].style.display = 'block' */
        e.target.style.borderColor = '#f5222d'
        e.target.classList.add('antd-input-vis')
      }
      if (wrapper.dataList[0].limitRange) {
        switch (wrapper.dataList[0].limitRange.type) {
          case 'GT':
            if (e.target.value.length < wrapper.dataList[0].limitRange.value) {
              /* this.$refs[wrapper.id][0].style.display = 'block' */
              const ref = 'input' + wrapper.id
              this.$refs[ref][0].$el.style.borderColor = '#f5222d'
              e.target.classList.add('antd-input-vis')
            } else {
              this.$refs[wrapper.id][0].style.display = 'none'
              e.target.style.borderColor = '#d9d9d9'
              e.target.classList.remove('antd-input-vis')
            }
            break
          case 'LT':
            if (e.target.value.length > wrapper.dataList[0].limitRange.value) {
              /* this.$refs[wrapper.id][0].style.display = 'block' */
              const ref = 'input' + wrapper.id
              this.$refs[ref][0].$el.style.borderColor = '#f5222d'
              e.target.classList.add('antd-input-vis')
            } else {
              this.$refs[wrapper.id][0].style.display = 'none'
              e.target.style.borderColor = '#d9d9d9'
              e.target.classList.remove('antd-input-vis')
            }
            break
          case 'BETWEEN':
            if (
              e.target.value.length < wrapper.dataList[0].limitRange.start ||
              e.target.value.length > wrapper.dataList[0].limitRange.end
            ) {
              /* this.$refs[wrapper.id][0].style.display = 'block' */
              const ref = 'input' + wrapper.id
              this.$refs[ref][0].$el.style.borderColor = '#f5222d'
              e.target.classList.add('antd-input-vis')
            } else {
              this.$refs[wrapper.id][0].style.display = 'none'
              e.target.style.borderColor = '#d9d9d9'
              e.target.classList.remove('antd-input-vis')
            }
            break
        }
      }
      /* item.canSync = false */
    },
    delItem(wrapper) {
      wrapper.dataList.forEach(item => {
        if (item.formValues.length > 1) item.formValues.pop()
      })
    },
    addItem(wrapper) {
      wrapper.dataList.forEach(item => {
        item.formValues.push({ value: undefined })
      })
    },
    inputNumberChange(e, wrapper, item) {
      const ref = 'input' + wrapper.id
      if (e && wrapper.dataList[0].valueRequired == 'YES') {
        this.$refs[ref][0].$el.classList.remove('ant-input-number-vis')
      } else if (!e && wrapper.dataList[0].valueRequired == 'YES') {
        this.$refs[ref][0].$el.classList.add('ant-input-number-vis')
      }
      if (e && !/(^[0-9]\d*$)/.test(e)) {
        this.$refs[wrapper.id][0].style.display = 'flex'
        this.$refs[ref][0].$el.classList.add('ant-input-number-vis')
      } else if (e && /(^[0-9]\d*$)/.test(e)) {
        this.$refs[wrapper.id][0].style.display = 'none'
        this.$refs[ref][0].$el.classList.remove('ant-input-number-vis')
      }
      /* item.canSync = false */
    },
    inputNumberBlur(e, wrapper, item) {
      const ref = 'input' + wrapper.id
      if (e.target.value && wrapper.dataList[0].valueRequired == 'YES') {
        this.$refs[ref][0].$el.classList.remove('ant-input-number-vis')
      } else if (!e.target.value && wrapper.dataList[0].valueRequired == 'YES') {
        this.$nextTick(() => {
          this.$refs[ref][0].$el.classList.add('ant-input-number-vis')
        })
      }
      if (e.target.value && !/(^[0-9]\d*$)/.test(e.target.value)) {
        this.$nextTick(() => {
          this.$refs[ref][0].$el.classList.add('ant-input-number-vis')
          this.$refs[wrapper.id][0].style.display = 'flex'
        })
      } else if (e.target.value && /(^[0-9]\d*$)/.test(e.target.value)) {
        this.$refs[ref][0].$el.classList.remove('ant-input-number-vis')
        this.$refs[wrapper.id][0].style.display = 'none'
      }
      /* item.canSync = false */
    },
    selectChange(e, wrapper) {
      if (typeof e == 'string') e = [e]
      if (e) {
        if (e.length <= wrapper.dataList[0].selectCount) {
          wrapper.dataList[0].selectValues = e
          wrapper.dataList[0].formValues = []
          e.forEach(item => {
            wrapper.dataList[0].formValues.push({ value: item })
          })
        } else {
          e.pop()
        }
        if (e.length > 0 && wrapper.dataList[0].valueRequired == 'YES') {
          this.$refs[wrapper.id][0].style.display = 'none'
          const ref = 'select' + wrapper.id
          this.$refs[ref][0].$el.firstChild.classList.remove('ant-select-selection-vis')
        } else if (e.length == 0 && wrapper.dataList[0].valueRequired == 'YES') {
          /* this.$refs[wrapper.id][0].style.display = 'block' */
          const ref = 'select' + wrapper.id
          this.$refs[ref][0].$el.firstChild.classList.add('ant-select-selection-vis')
        }
      } else {
        wrapper.dataList[0].formValues = []
        e = null
      }
      /* item.canSync = false */
    },
    formSelectChange(e, data, dataList, index) {
      if (e) {
        if (e && dataList[0].valueRequired == 'YES') {
          this.$refs[data.id + '' + index][0].style.display = 'none'
          const ref = 'select' + data.id + index
          this.$refs[ref][0].$el.firstChild.classList.remove('ant-select-selection-vis')
        } else if (e.length == 0 && wrapper.dataList[0].valueRequired == 'YES') {
          /* this.$refs[data.id + '' + index][0].style.display = 'block' */
          const ref = 'select' + data.id + index
          this.$refs[ref][0].$el.firstChild.classList.add('ant-select-selection-vis')
        }
      } else {
        e = null
      }
    },
    async getFormData() {
      /* this.spinning=true */
      try {
        const res = await getAction('/productDetail/page', {
          current: 1,
          size: -1,
          relationId: this.params.chapterId,
          stageId: this.taskDetail.stageId,
          domain: 'TASK_DETAIL',
          type: 'CHAPTER',
          editType: 'TASK'
        })
        if (res.success) {
          if (!res.data.records.length) this.$emit('setIsCoefficientPar')
          res.data.records.forEach(item => {
            if (item.cardList) {
              item.cardList.forEach(value => {
                value.isTagShow = false
                if (value.dataList) {
                  value.dataList.forEach(i => {
                    i.selectValues = []
                    if (!i.formValues) {
                      i.formValues = []
                      i.formValues.push({ value: null })
                      i.type == 'TAG' && (i.formValues[0].value = undefined)
                    } else {
                      i.formValues.forEach(select => {
                        if (i.options) {
                          i.options.forEach(option => {
                            if (option.value == select.value) {
                              option.checked = true
                            }
                          })
                        }
                        if (select.value) {
                          i.selectValues.push(select.value)
                        }
                      })
                    }
                  })
                } else {
                  value.dataList = [
                    {
                      formValues: [{ value: null }]
                    }
                  ]
                }
              })
            } else {
              item.cardList = []
            }
          })
          this.spinning = false
          this.$emit('setTabVis')
          this.formData = [...res.data.records]
          /*  document.addEventListener('click', this.bodyCloseMenus) */
          this.oldData = JSON.parse(JSON.stringify(this.formData))
          this.$forceUpdate()
        } else {
          this.spinning = false
          this.$message.error(res.msg || res.message)
        }
      } catch (err) {
        this.spinning = false
        console.error(err)
      }
    },
    async editProduction() {
      this.validate = true
      if (this.isEditProduction) {
        console.log(this.formData)
        this.formData.forEach(item => {
          if (item.cardList) {
            item.cardList.forEach(wrapper => {
              if (wrapper.dataList.length == 1) {
                if (wrapper.type == 'INPUT') {
                  if (wrapper.dataList[0].valueRequired == 'YES' && !(wrapper.dataList[0].formValues[0].value + '')) {
                    /* this.$refs[wrapper.id][0].style.display = 'block' */
                    const ref = 'input' + wrapper.id
                    this.$refs[ref][0].$el.style.borderColor = '#f5222d'
                    this.$refs[ref][0].$el.classList.add('antd-input-vis')
                    this.validate = false
                  } else if (wrapper.dataList[0].valueRequired == 'YES' && wrapper.dataList[0].formValues[0].value) {
                    if (wrapper.dataList[0].limitRange) {
                      switch (wrapper.dataList[0].limitRange.type) {
                        case 'GT':
                          if (wrapper.dataList[0].formValues[0].value.length < wrapper.dataList[0].limitRange.value) {
                            /* this.$refs[wrapper.id][0].style.display = 'block' */
                            const ref = 'input' + wrapper.id
                            this.$refs[ref][0].$el.style.borderColor = '#f5222d'
                            this.$refs[ref][0].$el.classList.add('antd-input-vis')
                            this.validate = false
                          } else {
                            const ref = 'input' + wrapper.id
                            this.$refs[wrapper.id][0].style.display = 'none'
                            this.$refs[ref][0].$el.style.borderColor = '#d9d9d9'
                            this.$refs[ref][0].$el.classList.remove('antd-input-vis')
                          }
                          break
                        case 'LT':
                          if (wrapper.dataList[0].formValues[0].value.length > wrapper.dataList[0].limitRange.value) {
                            /* this.$refs[wrapper.id][0].style.display = 'block' */
                            const ref = 'input' + wrapper.id
                            this.$refs[ref][0].$el.style.borderColor = '#f5222d'
                            this.$refs[ref][0].$el.classList.add('antd-input-vis')
                            this.validate = false
                          } else {
                            const ref = 'input' + wrapper.id
                            this.$refs[wrapper.id][0].style.display = 'none'
                            this.$refs[ref][0].$el.style.borderColor = '#d9d9d9'
                            this.$refs[ref][0].$el.classList.remove('antd-input-vis')
                          }
                          break
                        case 'BETWEEN':
                          if (
                            wrapper.dataList[0].formValues[0].value.length < wrapper.dataList[0].limitRange.start ||
                            wrapper.dataList[0].formValues[0].value.length > wrapper.dataList[0].limitRange.end
                          ) {
                            /* this.$refs[wrapper.id][0].style.display = 'block' */
                            const ref = 'input' + wrapper.id
                            this.$refs[ref][0].$el.style.borderColor = '#f5222d'
                            this.$refs[ref][0].$el.classList.add('antd-input-vis')
                            this.validate = false
                          } else {
                            const ref = 'input' + wrapper.id
                            this.$refs[wrapper.id][0].style.display = 'none'
                            this.$refs[ref][0].$el.style.borderColor = '#d9d9d9'
                            this.$refs[ref][0].$el.classList.remove('antd-input-vis')
                          }
                          break
                      }
                    }
                  } else if (wrapper.dataList[0].valueRequired == 'YES' && !wrapper.dataList[0].formValues[0].value) {
                    const ref = 'input' + wrapper.id
                    this.$refs[ref][0].$el.classList.add('ant-input-number-vis')
                    this.validate = false
                  }
                } else if (wrapper.type == 'INPUT_NUMBER') {
                  if (wrapper.dataList[0].valueRequired == 'YES' && !(wrapper.dataList[0].formValues[0].value + '')) {
                    /* this.$refs[wrapper.id][0].style.display = 'block' */
                    const ref = 'input' + wrapper.id
                    this.$refs[ref][0].$el.classList.add('ant-input-number-vis')
                    this.validate = false
                  }
                } else if (wrapper.type == 'SELECT') {
                  if (wrapper.dataList[0].valueRequired == 'YES' && !(wrapper.dataList[0].formValues[0].value + '')) {
                    /* this.$refs[wrapper.id][0].style.display = 'block' */
                    const ref = 'select' + wrapper.id
                    this.$refs[ref][0].$el.firstChild.classList.add('ant-select-selection-vis')
                    this.validate = false
                  }
                } else if (wrapper.type == 'DYNAMIC_FORM') {
                  wrapper.dataList.forEach(data => {
                    data.formValues.forEach((formValue, index) => {
                      if (wrapper.dataList[0].valueRequired == 'YES' && !formValue.value) {
                        if (data.type == 'INPUT') {
                          /* this.$refs[data.id + '' + index][0].style.display = 'block' */
                          const ref = 'input' + data.id + index
                          this.$refs[ref][0].$el.style.borderColor = '#f5222d'
                          this.$refs[ref][0].$el.classList.add('antd-input-vis')
                          this.validate = false
                        } else {
                          /* this.$refs[data.id + '' + index][0].style.display = 'block' */
                          const ref = 'select' + data.id + index
                          this.$refs[ref][0].$el.firstChild.classList.add('ant-select-selection-vis')
                          this.validate = false
                        }
                      }
                    })
                  })
                }
              } else {
                wrapper.dataList.forEach(data => {
                  data.formValues.forEach((formValue, index) => {
                    if (wrapper.dataList[0].valueRequired == 'YES' && !(formValue.value + '')) {
                      if (data.type == 'INPUT') {
                        /* this.$refs[data.id + '' + index][0].style.display = 'block' */
                        const ref = 'input' + data.id + index
                        this.$refs[ref][0].$el.style.borderColor = '#f5222d'
                        this.validate = false
                      } else {
                        /* this.$refs[data.id + '' + index][0].style.display = 'block' */
                        const ref = 'select' + data.id + index
                        this.$refs[ref][0].$el.style.border = '1px solid #f5222d'
                        this.$refs[ref][0].$el.style.borderRadius = '4px'
                        this.validate = false
                      }
                    }
                  })
                })
              }
            })
          }
        })
        if (this.validate) {
          this.spinning = true
          try {
            this.formData.forEach((item, index) => {
              if (item.cardList) {
                item.cardList.forEach(value => {
                  value.dataList.forEach(i => {
                    delete i.selectValues
                  })
                })
              }
              item.cardList.forEach((wrapper, idx) => {
                if (
                  wrapper.dataList[0].formValues[0].value !=
                  this.oldData[index].cardList[idx].dataList[0].formValues[0].value
                ) {
                  item.canSync = false
                }
              })
            })
            const res = await postAction('/productDetail/batch', {
              list: this.formData,
              editType: 'TASK',
              domain: 'TASK_DETAIL',
              type: 'CHAPTER',
              relationId: this.params.chapterId
            })
            /*             await postAction('/tasks/update', {
              user: this.taskDetail.user,
              platformId: this.taskDetail.platformId,
              productionId: this.taskDetail.productionId,
              taskId: this.taskDetail.taskId
            }) */
            if (res.success) {
              this.$message.success('保存成功')
              this.oldData = JSON.parse(JSON.stringify(this.formData))
              this.getFormData()
              this.isEditProduction = false
              this.spinning = false
              getAction('/productDetail/syncKpiAsync/task/'+this.params.taskId)
            } else {
              this.$message.error(res.msg || res.message)
              this.spinning = false
            }
          } catch (err) {
            this.$message.error(err)
            this.spinning = false
          }
        } else {
          this.$message.error('请填写必填项')
        }
      } else {
        this.isEditProduction = true
        this.spinning = false
      }
    },
    handelPlatformChange() {
      this.platformList.forEach(one => {
        if (one.platformId == this.formData.platformId) {
          this.formData.hasChapterTitle = one.hasChapterTitle
          this.formData.hasChapterCoverImage = one.hasChapterCoverImage
        }
      })
    },
    handelFormDataChange(formData) {
      // console.log({ formData })
    }
  }
}
</script>

<style lang="less" scoped>
.required:before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.no-required:before {
  display: inline-block;
  margin-right: 4px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
  opacity: 0;
}
::-webkit-scrollbar-button {
  height: 4px;
}
::-webkit-scrollbar {
  width: 0px;
}
.ant-form {
  &:hover {
    &::-webkit-scrollbar {
      width: 12px;
    }
  }
}
/* ::-webkit-scrollbar-track {
  border-radius: 0;
}
::-webkit-scrollbar-track-piece {
  border-radius: 0;
}
::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #c1c1c1;
} */
.dis-tag {
  width: 18vw;
  min-height: 30px;
  background-color: #f5f5f5;
  border-radius: 4px;
  margin-top: 7px;
  border: 1px solid #e0e0e0;
  cursor: not-allowed;
  display: flex;
  align-items: center;
  position: relative;
}
/deep/ .antd-input-vis {
  box-shadow: 0 0 0 0 !important;
}
/deep/ .ant-input-number-vis {
  border: 1px solid #f5222d !important;
  border-radius: 4px !important;
  box-shadow: 0 0 0 0 #f5222d !important;
}
/deep/ .ant-select-selection-vis {
  border-color: #f5222d !important;
  box-shadow: 0 0 0 0 #f5222d !important;
}
.zsxz {
  float: right;
  font-size: 12px;
  color: rgb(191, 191, 191);
  margin: auto 0;
  margin-left: 5px;
}
.tag-select {
  position: relative;
  width: 100%;
  height: 32px;
  margin: auto 0;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  cursor: pointer;
  &:hover {
    border: 1px solid rgb(124, 170, 235);
  }
  .ant-tag {
    color: #1890ff;
    background: #e6f7ff;
    border-color: #91d5ff;
    border: 1px solid #91d5ff;
    cursor: pointer;
  }
}
.tag-select-show {
  position: relative;
  width: 100%;
  height: 32px;
  margin: auto 0;
  background-color: #fff;
  box-shadow: 0 0 0 1.5px rgb(124, 170, 235);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  .ant-tag {
    color: #1890ff;
    background: #e6f7ff;
    border-color: #91d5ff;
    border: 1px solid #91d5ff;
    cursor: pointer;
  }
  .ant-tag-checkable-checked {
    background-color: #5389df;
    color: #fff;
    cursor: pointer;
  }
}
/deep/ .ant-input-vis {
  box-shadow: 0 0 0 0 !important;
}
/deep/ .tag-select-open {
  position: absolute;
  border: 1px solid #d9d9d9;
  width: 503px;
  top: 36px;
  display: flex;
  left: -2px;
  height: max-content;
  padding-bottom: 3px;
  overflow-y: overlay;
  flex-wrap: wrap;
  background-color: #fff;
  padding-left: 7px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgb(235, 233, 233);
  transition: 0.2s;
  overflow: hidden;
  align-content: flex-start;
  z-index: 1000;
  .ant-tag {
    height: 25px;
    margin-top: 5px;
    line-height: 23px;
    &:hover {
      cursor: pointer;
    }
  }
}
.tag-select-close {
  position: absolute;
  display: none;
  transition: 0.2s;
  width: 503px;
  top: 30px;
  left: -2px;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px black;
}
/deep/ .ant-tag-checkable {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
  border: 1px solid #91d5ff;
}
/deep/ .edit-form {
  .ant-form-item-label > label::after {
    display: none;
  }
}
/deep/ .show-form {
  overflow-y: overlay;
  height: 62.2vh;
  .ant-form-item-label > label::after {
    display: none;
  }
}
.description-div-one {
  font-size:12px;
  width: 600px;
  word-break: break-word;
  padding-left: 14px;
  color: #bfbfbf;
}
.description-div {
  font-size:12px;
  margin-top: -11px;
  padding-left: 14px;
  line-height: 20px;
  width: 135px;
  white-space: pre-line;
  word-break: break-word;
  color: #bfbfbf;
}
/deep/ .ant-badge-status-processing {
  background-color: rgba(0, 0, 0, 0.65);
  margin-right: 3px;
  &::after {
    border: none;
  }
}
.select-span {
  position: absolute;
  font-size: 12px;
  right: 35px;
  color: rgb(191, 191, 191);
}
/deep/ .ant-form-item-children .form-select {
  margin-bottom: 2px;
  label {
    top: 6px;
  }
}
/deep/ .show-form {
  .ant-form-item {
    color: rgba(0, 0, 0, 0.65);
    width: 600px;
    margin-bottom: 12px;
  }
  .ant-form-item-children label {
    color: rgba(0, 0, 0, 0.65);
    top: 0;
  }
}
/deep/ .ant-form-item-control-wrapper {
  flex: 1;
}
/deep/ .only {
  margin-left: -101px !important;
}
/deep/ .ant-form-item {
  max-width: 100%;
  display: flex;
  margin-bottom: 12px;
  input,
  .ant-input-number {
    width: 18vw;
  }
}
</style>
